<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">我的账单</div>
        <div class="right"></div>
      </div>
      <div class="section">
        <pullrefresh :disabled="true" @onLoad="onLoad" ref="pull" v-show="BillList.length>0">
          <div class="list">
            <div class="item flex-between" v-for="item in BillList" :key="item.id">
              <div class="item-left">
                <div class="name">{{item.desc}}</div>
                <div class="time">{{item.createdAt|time}}</div>
              </div>
              <div class="item-center" :class="{fail:item.status == -1}">{{item.status == 1?'成功':(item.status==0?'进行中':'失败')}}</div>
              <div class="money">{{item.mark==1?'+':item.mark==2?'':'-'}}{{item.moneyPrePrice}}元</div>
            </div>
          </div>
        </pullrefresh>
        <van-loading type="spinner" color="#f21313" v-show="BillList&&BillList.length==0 && !noData"/>
        <nodata v-show="noData"/>
      </div>
    </div>
  </div>
</template>
<script>
import {getGameBill} from '@/api/app.js';
import pullrefresh from "@/components/PullRefresh/index.vue";
export default {
  components:{
    pullrefresh
  },
  data(){
    return{
      pageNum:1,//页码
      BillList:[],//账单
      noData:false,//暂无数据
    }
  },
  created(){
    this.getGameBill()
  },
  methods:{
    async getGameBill(){//得到账单数据
      let req = {
        pageNum:this.pageNum,
        pageSize:20
      }
      let ret = await this.$Api(getGameBill,req);
      if(!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if(ret.code == 200){
        let list = ret.data.list?ret.data.list:[];
        this.BillList = this.BillList.concat(list);
        if(list.length < 20){
          this.$refs.pull.finished = true;
        }else{
          this.$refs.pull.finished = false;
        }
        if(this.pageNum == 1 && list.length == 0){
          this.noData = true;
        }
      }
    },
    onLoad(){}
  }
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.center{
  font-size:20px;
  font-weight: bold;
  color:#ffffff;
}
.right{
  width:22px;
  color:#ffffff;
  font-size:14px;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:6px 16px 30px;
  box-sizing: border-box;
}
.item{
  align-items: center;
  border-bottom:1px solid #d5d6d6;
  padding:11px 0;
}
.item-left{
  max-width: 260px;
}
.name{
  font-weight:bold;
}
.time{
  margin-top:4px;
  color:#707170;
}
.item-center{
  box-sizing: border-box;
  margin:0 10px;
  color:#ffffff;
  font-size:13px;
  padding:5px 10px;
  background: $vermillion;
  border-radius: 7px;
  white-space:nowrap;
}
.money{
  min-width: 60px;
  text-align: right;
  color:$vermillion;
  word-wrap:break-word;
}
.fail{
  background:#707170;
}
</style>
